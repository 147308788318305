import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AuthService } from './auth/auth.service';
import { ReleaseManagerService } from './release-manager.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  isIframe: boolean;

  constructor(
    private readonly releaseManager: ReleaseManagerService,
    public readonly translateService: TranslateService,
    public readonly authService: AuthService,
  ) {
    console.info(`Commit hash: ${environment.commitHash}`);
    console.info(`Commit tag: ${environment.commitTag}`);
    // this language will be used as a fallback when a translation isn't found in the current language
    translateService.setDefaultLang('en');

    // the lang to use
    // if not set, it will use the browser language
    const currentLang = localStorage.getItem('lang-iso');
    if (currentLang) {
      translateService.use(currentLang);
    } else {
      const browserLang = navigator?.language?.split('-')[0];
      if (browserLang === 'it' || browserLang === 'en' || browserLang === 'pt-br') {
        translateService.use(browserLang);
        localStorage.setItem('lang-iso', browserLang);
      } else {
        // browser language not supported by the application so use eng as default
        localStorage.setItem('lang-iso', 'en');
      }
    }

    if (environment.environmentName !== 'dev') {
      this.releaseManager.init();
    }
  }

  ngOnInit(): void {
    this.authService.init();
  }
}
